import { graphql, useStaticQuery } from 'gatsby'

export const useCmsMenuQuery = () => {
  const data = useStaticQuery(
    graphql`
      query cmsMenuQuery {
        cmsMenu {
          sections {
            data
          }
        }
      }
    `
  )

  const menuData: TCategory[] = data?.cmsMenu?.sections?.[0]?.data?.categories

  const sidebarData: SidebarMenu[] = data?.cmsMenu?.sections?.[0]?.data?.sidebar

  return {
    data,
    menuData,
    sidebarData,
  }
}
