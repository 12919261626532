export default function CloseGeoLocator() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.15976 0.15976C0.372773 -0.0532533 0.718136 -0.0532533 0.931149 0.15976L6 5.22861L11.0689 0.15976C11.2819 -0.0532533 11.6272 -0.0532533 11.8402 0.15976C12.0533 0.372773 12.0533 0.718136 11.8402 0.931149L6.77139 6L11.8402 11.0689C12.0533 11.2819 12.0533 11.6272 11.8402 11.8402C11.6272 12.0533 11.2819 12.0533 11.0689 11.8402L6 6.77139L0.931149 11.8402C0.718136 12.0533 0.372773 12.0533 0.15976 11.8402C-0.0532533 11.6272 -0.0532533 11.2819 0.15976 11.0689L5.22861 6L0.15976 0.931149C-0.0532533 0.718136 -0.0532533 0.372773 0.15976 0.15976Z"
        fill="#E5002B"
      />
    </svg>
  )
}
