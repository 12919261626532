/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-key */
import Carousel from '../../ui/Carousel'

type texts = {
  texts: string[]
}

function StickBarCustom(texts: texts) {
  const allTexts = texts
  const size = { width: 12, height: 12 }

  return (
    <div className="custom-stickBar">
      <Carousel
        infiniteMode
        controls="navigationArrows"
        timeout={16000}
        transition={{
          duration: 600,
          property: 'transform',
        }}
        iconArrowSize={size}
      >
        {allTexts?.texts.map((text: string) => {
          return (
            <div
              className="text-container"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )
        })}
      </Carousel>
    </div >
  )
}

export default StickBarCustom
