export const categoriesDataMock = {
  data: {
    categories: [
      {
        slug: 'animales-y-mascotas',
        name: 'Animales y Mascotas',
        hasChildren: true,
        link: 'https://tatauyqa.vtex.app/perfumeria',
        src: 'https://tatauyqa.vtexassets.com/arquivos/iconBebidas.png',
        children: [
          {
            slug: 'aves',
            name: 'Aves',
            hasChildren: true,
            children: [
              {
                slug: 'alimentos',
                name: 'Alimentos',
                link: 'https://tatauyqa.vtex.app/alimentos',
                hasChildren: false,
              },
              {
                slug: 'anillos',
                name: 'Anillos',
                link: 'https://tatauyqa.vtex.app/anillos',
                hasChildren: false,
              },
              {
                slug: 'aves',
                name: 'Aves',
                link: 'https://tatauyqa.vtex.app/aves',
                hasChildren: false,
              },
              {
                slug: 'cetreria',
                name: 'Cetrería',
                hasChildren: false,
              },
              {
                slug: 'comederos-y-bebederos',
                name: 'Comederos y Bebederos',
                hasChildren: false,
              },
              {
                slug: 'huevos-fertiles',
                name: 'Huevos Fértiles',
                hasChildren: false,
              },
              {
                slug: 'incubadoras',
                name: 'Incubadoras',
                hasChildren: false,
              },
              {
                slug: 'jaulas',
                name: 'Jaulas',
                hasChildren: false,
              },
              {
                slug: 'juguetes',
                name: 'Juguetes',
                hasChildren: false,
              },
              {
                slug: 'nidos',
                name: 'Nidos',
                hasChildren: false,
              },
              {
                slug: 'otros',
                name: 'Otros',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'conejos',
            name: 'Conejos',
            hasChildren: true,
            children: [
              {
                slug: 'conejos',
                name: 'Conejos',
                hasChildren: false,
              },
              {
                slug: 'jaulas',
                name: 'Jaulas',
                hasChildren: false,
              },
              {
                slug: 'otros',
                name: 'Otros',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'equinos',
            name: 'Equinos',
            hasChildren: true,
            children: [
              {
                slug: 'articulos-para-equitacion',
                name: 'Artículos para Equitación',
                hasChildren: false,
              },
              {
                slug: 'caballos-y-yeguas',
                name: 'Caballos y Yeguas',
                hasChildren: false,
              },
              {
                slug: 'carretas',
                name: 'Carretas',
                hasChildren: false,
              },
              {
                slug: 'fardos',
                name: 'Fardos',
                hasChildren: false,
              },
              {
                slug: 'higiene-y-cuidado',
                name: 'Higiene y Cuidado',
                hasChildren: false,
              },
              {
                slug: 'suplementos-para-caballos',
                name: 'Suplementos para Caballos',
                hasChildren: false,
              },
              {
                slug: 'otros',
                name: 'Otros',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'gatos',
            name: 'Gatos',
            hasChildren: true,
            children: [
              {
                slug: 'accesorios',
                name: 'Accesorios',
                hasChildren: false,
              },
              {
                slug: 'alimento--premios-y-suplemento',
                name: 'Alimento, Premios y Suplemento',
                hasChildren: false,
              },
              {
                slug: 'camas-y-cuchas',
                name: 'Camas y Cuchas',
                hasChildren: false,
              },
              {
                slug: 'comederos-y-bebederos',
                name: 'Comederos y Bebederos',
                hasChildren: false,
              },
              {
                slug: 'estetica-e-higiene',
                name: 'Estética e Higiene',
                hasChildren: false,
              },
              {
                slug: 'gatos',
                name: 'Gatos',
                hasChildren: false,
              },
              {
                slug: 'juguetes',
                name: 'Juguetes',
                hasChildren: false,
              },
              {
                slug: 'puertas-y-rampas',
                name: 'Puertas y Rampas',
                hasChildren: false,
              },
              {
                slug: 'viaje-y-paseo',
                name: 'Viaje y Paseo',
                hasChildren: false,
              },
              {
                slug: 'otros',
                name: 'Otros',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'insectos',
            name: 'Insectos',
            hasChildren: true,
            children: [
              {
                slug: 'cucarachas',
                name: 'Cucarachas',
                hasChildren: false,
              },
              {
                slug: 'escarabajos',
                name: 'Escarabajos',
                hasChildren: false,
              },
              {
                slug: 'grillos',
                name: 'Grillos',
                hasChildren: false,
              },
              {
                slug: 'otros',
                name: 'Otros',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'peces',
            name: 'Peces',
            hasChildren: true,
            children: [
              {
                slug: 'alimentos',
                name: 'Alimentos',
                hasChildren: false,
              },
              {
                slug: 'peceras-y-accesorios',
                name: 'Peceras y Accesorios',
                hasChildren: false,
              },
              {
                slug: 'peces',
                name: 'Peces',
                hasChildren: false,
              },
              {
                slug: 'otros',
                name: 'Otros',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'perros',
            name: 'Perros',
            hasChildren: true,
            children: [
              {
                slug: 'adiestramiento',
                name: 'Adiestramiento',
                hasChildren: false,
              },
              {
                slug: 'alimento--premios-y-suplemento',
                name: 'Alimento, Premios y Suplemento',
                hasChildren: false,
              },
              {
                slug: 'bebederos-y-comederos',
                name: 'Bebederos y Comederos',
                hasChildren: false,
              },
              {
                slug: 'camas-y-cuchas',
                name: 'Camas y Cuchas',
                hasChildren: false,
              },
              {
                slug: 'higiene-y-estetica',
                name: 'Higiene y Estética',
                hasChildren: false,
              },
              {
                slug: 'juguetes-para-perros',
                name: 'Juguetes para Perros',
                hasChildren: false,
              },
              {
                slug: 'perros-de-cruza',
                name: 'Perros de Cruza',
                hasChildren: false,
              },
              {
                slug: 'perros-de-raza',
                name: 'Perros de Raza',
                hasChildren: false,
              },
              {
                slug: 'perros-en-adopcion',
                name: 'Perros en Adopción',
                hasChildren: false,
              },
              {
                slug: 'puertas--rampas-y-corrales',
                name: 'Puertas, Rampas y Corrales',
                hasChildren: false,
              },
              {
                slug: 'ropa-para-perros',
                name: 'Ropa para Perros',
                hasChildren: false,
              },
              {
                slug: 'sillas-de-rueda',
                name: 'Sillas de Rueda',
                hasChildren: false,
              },
              {
                slug: 'viaje-y-paseo',
                name: 'Viaje y Paseo',
                hasChildren: false,
              },
              {
                slug: 'otros-productos',
                name: 'Otros Productos',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'reptiles-y-anfibios',
            name: 'Reptiles y Anfibios',
            hasChildren: true,
            children: [
              {
                slug: 'alimentos',
                name: 'Alimentos',
                hasChildren: false,
              },
              {
                slug: 'otros',
                name: 'Otros',
                hasChildren: false,
              },
              {
                slug: 'terrarios-y-tortugueros',
                name: 'Terrarios y Tortugueros',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'roedores',
            name: 'Roedores',
            hasChildren: true,
            children: [
              {
                slug: 'alimentos-y-vitaminas',
                name: 'Alimentos y Vitaminas',
                hasChildren: false,
              },
              {
                slug: 'bebederos-y-comederos',
                name: 'Bebederos y Comederos',
                hasChildren: false,
              },
              {
                slug: 'casas',
                name: 'Casas',
                hasChildren: false,
              },
              {
                slug: 'jaulas-y-habitats',
                name: 'Jaulas y Habitats',
                hasChildren: false,
              },
              {
                slug: 'juguetes',
                name: 'Juguetes',
                hasChildren: false,
              },
              {
                slug: 'roedores',
                name: 'Roedores',
                hasChildren: false,
              },
              {
                slug: 'shampoos',
                name: 'Shampoos',
                hasChildren: false,
              },
              {
                slug: 'sustratos',
                name: 'Sustratos',
                hasChildren: false,
              },
            ],
          },
          {
            slug: 'otros',
            name: 'Otros',
            hasChildren: false,
            children: [],
          },
        ],
      },
      {
        slug: 'bebidas whiskys',
        name: 'Bebidas',
        hasChildren: true,
        src: 'https://tatauyqa.vtexassets.com/arquivos/iconBebidas.png',
        children: [
          {
            name: 'Whiskys, bebidas blancas y aperitivos',
            hasChildren: true,
            children: [
              {
                name: 'Whiskys',
                link: 'https://tatauyqa.preview.vtex.app/bebidas/whiskys-bebidas-blancas-y-aperitivos/whiskys',
                hasChildren: false,
              },
            ],
          },
        ],
      },
    ],
  },
}
