import type { FC } from 'react'

export const LoadingGif: FC<IconProps> = () => (
  <img
    height={16}
    width={16}
    src="https://nebula-cdn.kampyle.com/us/resources/onsite/images/kloader.gif"
    alt="loading"
  />
)
