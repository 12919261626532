export const collectionsDataMock = {
  allItems: [
    {
      text: 'Nuestras Marcas',
      link: '/ayuda',
    },
    {
      text: 'Revista',
      link: '/ayuda',
    },
    {
      text: 'Ayuda',
      link: '/ayuda',
    },
    {
      text: 'Novedades',
      link: '/ayuda',
    },
    {
      text: 'Locales',
      link: '/ayuda',
    },
  ],
  contactText:
    '<p className="navlinks__contact-text"> Televendas <b>08008282</b></p>',
  redButtonText: 'Bombazos',
}
