import { MyOrdersIcon } from 'src/components/ui/Icons'
import { ButtonLink } from 'src/components/ui/Button'
import { useSession } from 'src/sdk/session'
import styles from 'src/components/ui/Button/button.module.scss'
import { secureSubdomain, loginUrl} from '../../../../../../store.config'

const ButtonMyOrders = () => {
  const { person } = useSession()

  return (
    <ButtonLink
      data-fs-button-signin-link
      href={person?.id ? secureSubdomain + 'api/io/account#/orders' : loginUrl}
      className={`${styles.fsButton} text__title-mini signin-link`}
      variant="tertiary"
    >
      <MyOrdersIcon />
    </ButtonLink>
  )
}

export default ButtonMyOrders
