import GeoPromoIcon from 'src/components/ui/Icons/GeoLocatorPromotionIcon'
import InnerIcon from 'src/components/ui/Icons/GeoLocatorInnerIcon'
import CloseGeoIcon from 'src/components/ui/Icons/CloseGeoLocator'
import { validateSession, sessionStore } from 'src/sdk/session'
import { getSellerData } from 'src/utils/getGeolocations'
import { useEffect } from 'react'

import { useGeolocations } from '../../hooks/useGeolocations'

interface Props {
  selectedOption: string
  setShowGeo: (value: boolean) => void
  setSelectedOption: (value: string) => void
  newLocationData: LocationsCookie
  setNewLocationData: (value: LocationsCookie) => void
}

const GeoLocatorModal = ({
  setShowGeo,
  setSelectedOption,
  selectedOption,
  newLocationData,
  setNewLocationData,
}: Props) => {
  const { geolocations } = useGeolocations()

  const session = {
    currency: {
      code: '$',
      symbol: '$',
    },
    locale: 'es-UY',
    channel: '{"salesChannel":"4","regionId":""}',
    country: 'URY',
    postalCode: null,
    person: null,
  }

  const geoData = Object.entries(geolocations)

  const geolocationsSellersList: string[] = geoData.map((item: any) => {
    return item[1]?.Name
  })

  const locationData = JSON.parse(localStorage.getItem('Location') ?? '{}')

  const handleGeolocatorSubmit = async () => {
    setShowGeo(false)
    localStorage.setItem(
      'Location',
      JSON.stringify(getSellerData(selectedOption))
    )

    try {
      await validateSession({
        ...session,
        postalCode: newLocationData?.postalCode,
      })
        .then((newSession) => {
          if (newSession) {
            sessionStore.set(newSession)
          }
        })
        .then(() => {
          window.location.reload()
        })
    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    setNewLocationData(getSellerData(selectedOption))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption])

  return (
    <div className="geo__body-wrapper">
      <div className="geoLocator-modal-container">
        <div className="geoLocator">
          <div className="geoLocator-innerContainer">
            <div className="geoLocator-close-modal-container">
              <p className="geoLocator-title">¡Bienvenido!</p>
              {!!Object.keys(locationData).length && (
                <button
                  className="geoLocator-close-modal-icon-container"
                  onClick={() => {
                    setShowGeo(false)
                    setSelectedOption(locationData?.description ?? '')
                  }}
                >
                  <CloseGeoIcon />
                </button>
              )}
            </div>
            <InnerIcon />
            <p className="geoLocator-select-text">
              Seleccioná una localidad para iniciar la compra.
            </p>

            <select
              className={`geoLocator-select ${
                selectedOption === '' || selectedOption === 'Ta-Ta'
                  ? 'geoLocator-select__disable'
                  : ''
              }`}
              value={selectedOption}
              onChange={(event) => {
                setSelectedOption(event.target.value)
              }}
            >
              {geolocationsSellersList.map((option: string, idx) => {
                return (
                  <option value={option} key={idx}>
                    {getSellerData(option)?.description}
                  </option>
                )
              })}
            </select>

            <button
              type="button"
              className="geoLocator-button"
              disabled={selectedOption === '' || selectedOption === 'Ta-Ta'}
              onClick={() => {
                handleGeolocatorSubmit()
              }}
            >
              Confirmar
            </button>

            {locationData && (
              <div className="geoLocator-promotions-container">
                <GeoPromoIcon />
                <p className="geoLocator-promotionalPrices">
                  Los precios y promociones son exclusivos online
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeoLocatorModal
