import type { FC } from 'react'

export const Edit: FC<IconProps> = ({ className }) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 14L0.46967 13.4697C0.329018 13.6103 0.25 13.8011 0.25 14H1ZM14 1L14.5303 0.46967C14.2374 0.176777 13.7626 0.176777 13.4697 0.46967L14 1ZM18 5L18.5303 5.53033C18.8232 5.23744 18.8232 4.76256 18.5303 4.46967L18 5ZM5 18V18.75C5.19891 18.75 5.38968 18.671 5.53033 18.5303L5 18ZM1 18H0.25C0.25 18.4142 0.585786 18.75 1 18.75L1 18ZM9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75V17.25ZM17.5 18.75C17.9142 18.75 18.25 18.4142 18.25 18C18.25 17.5858 17.9142 17.25 17.5 17.25V18.75ZM1.53033 14.5303L14.5303 1.53033L13.4697 0.46967L0.46967 13.4697L1.53033 14.5303ZM13.4697 1.53033L17.4697 5.53033L18.5303 4.46967L14.5303 0.46967L13.4697 1.53033ZM17.4697 4.46967L4.46967 17.4697L5.53033 18.5303L18.5303 5.53033L17.4697 4.46967ZM5 17.25H1V18.75H5V17.25ZM1.75 18V14H0.25V18H1.75ZM10.4697 4.53033L14.4697 8.53033L15.5303 7.46967L11.5303 3.46967L10.4697 4.53033ZM9 18.75H17.5V17.25H9V18.75Z"
      fill="white"
    />
  </svg>
)
