import type { FC } from 'react'
import Section from 'src/components/sections/Section'
import Link from 'src/components/ui/Link'
import { useUI } from 'src/sdk/ui/Provider'

const ContentSidebar: FC<SidebarMenu> = ({
  titleSidebar,
  subtitle,
  link,
  className = 'menu',
}) => {
  const { closeNavbar } = useUI()

  return (
    <div className={`${className} menu__items`}>
      <Section className="menu__sidebar">
        <Link href={link} onClick={() => closeNavbar()}>
          <h4 className="menu__sidebar__title">{titleSidebar}</h4>
        </Link>

        {subtitle?.map(({ name, haslink }, idx) => (
          <Link href={haslink ?? '/'} key={idx} onClick={() => closeNavbar()}>
            <h5 className="menu__sidebar__subtitle">{name}</h5>
          </Link>
        ))}
      </Section>
    </div>
  )
}

export default ContentSidebar
