function LogoMobile() {
  return (
    <img
      src="/logoMobile.svg"
      alt="TaTa Super Logo"
      width="64px"
      height="30px"
    />
  )
}

export default LogoMobile
