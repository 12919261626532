import { useState } from 'react'
import { List as UIList } from '@faststore/ui'

import Link from 'src/components/ui/Link'
import Accordion, { AccordionItem } from 'src/components/ui/Accordion'
import useWindowDimensions from 'src/components/hooks/useWindowDimensions'
import { useCmsHomeQuery } from '../../hooks/useCmsHomeQuery'

const mockLinks = {
  redLink: [
    {
      links: [
        {
          title: 'Sobre nosotros',
          URL: '/',
        },
        {
          title: 'Empleo',
          URL: '/',
        },
        {
          title: 'Locales',
          URL: '/',
        },
        {
          title: 'Nuestras sucursales',
          URL: '/',
        },
        {
          title: 'Compromiso sustentable',
          URL: '/',
        },
        {
          title: 'Código de ética',
          URL: '/',
        },
      ],
      redTitle: 'Ta Ta 2',
    },
    {
      links: [
        {
          title: 'Encomienda fácil',
          URL: '/',
        },
        {
          URL: '/',
          title: 'Disponible Ya',
        },
        {
          title: 'e-tickets',
          URL: '/',
        },
        {
          title: 'Gift Cards',
          URL: '/',
        },
      ],
      redTitle: 'Beneficios',
    },
    {
      links: [
        {
          URL: '/',
          title: 'Cómo comprar',
        },
        {
          URL: '/',
          title: 'Envío y retiro',
        },
      ],
      redTitle: 'Ayuda',
    },
    {
      links: [
        {
          title: 'Seguimiento de pedidos',
          URL: '/',
        },
        {
          title: 'Cambios y devoluciones',
          URL: '/lp/cambios-devoluciones',
        },
        {
          title: 'Términos y condiciones',
          URL: '/lp/terminos-y-condiciones',
        },
        {
          title: 'Politica de privacidad',
          URL: '/lp/politica-de-privacidad',
        },
        {
          title: 'Recuperar contraseña',
          URL: '/',
        },
        {
          title: 'Contáctanos',
          URL: '/',
        },
        {
          title: 'Preguntas frecuentes',
          URL: '/lp/preguntas-frecuentes',
        },
        {
          title: 'Limpiar Sesión',
          URL: '/',
        },
      ],
      redTitle: 'Mis Compras',
    },
  ],
}

function LinksList({ links }: RedLinks) {
  return (
    <UIList>
      {links?.map((item, index) => (
        <li key={index}>
          <Link variant="footer" href={item?.URL ?? '/#'}>
            {item?.title}
          </Link>
        </li>
      ))}
    </UIList>
  )
}

function FooterLinks() {
  const [indicesExpanded, setIndicesExpanded] = useState<Set<number>>(
    new Set([])
  )

  const { isMobile } = useWindowDimensions()
  const { footerLinks } = useCmsHomeQuery()

  if (!footerLinks) {
    return null
  }

  const redLinks = footerLinks?.redLink ?? mockLinks?.redLink

  const onChange = (index: number) => {
    if (indicesExpanded.has(index)) {
      indicesExpanded.delete(index)
      setIndicesExpanded(new Set(indicesExpanded))
    } else {
      setIndicesExpanded(new Set(indicesExpanded.add(index)))
    }
  }

  return (
    <section className="footer__links">
      {isMobile ? (
        <Accordion expandedIndices={indicesExpanded} onChange={onChange}>
          {redLinks.map((section, index) => (
            <AccordionItem
              key={section?.redtitle}
              isExpanded={indicesExpanded.has(index)}
              buttonLabel={section?.redtitle}
            >
              <LinksList links={section?.links} />
            </AccordionItem>
          ))}
        </Accordion>
      ) : (
        <>
          <div className="footer__links-columns">
            {redLinks?.map((section, index) => (
              <nav
                className={`footer__sections-${index}`}
                key={`${section?.redtitle} ${index}`}
              >
                <p className="text__title-mini">{section?.redtitle}</p>
                <LinksList links={section?.links} />
              </nav>
            ))}
          </div>
        </>
      )}
    </section>
  )
}

export default FooterLinks
