import { Button, Link } from '@faststore/ui'
import { useUI } from 'src/sdk/ui/Provider'

import ContentSidebar from './SidebarContent'

interface Props {
  sidebarData: SidebarMenu[]
  children: React.ReactNode
}

const Sidebar = ({ sidebarData, children }: Props) => {
  const { closeNavbar } = useUI()

  return (
    <>
      {sidebarData?.map(({ titleSidebar, subtitle, link, showFooter }, idx) => {
        return (
          <div key={idx}>
            {!showFooter && (
              <>
                <ContentSidebar
                  className="primary"
                  titleSidebar={titleSidebar}
                  subtitle={subtitle}
                  link={link ?? '/'}
                  key={idx}
                />
              </>
            )}
          </div>
        )
      })}
      <div className="menu__sidebar--border" />
      {children}
      <Link href="https://shop.tata.com.uy/" onClick={() => closeNavbar()}>
        <Button className="menu__sidebar__shop">IR PARA SHOP</Button>
      </Link>
      {sidebarData?.map(({ titleSidebar, subtitle, link, showFooter }, idx) => {
        return (
          <div key={idx}>
            {showFooter && (
              <>
                <div className="menu__sidebar--border" />
                <ContentSidebar
                  titleSidebar={titleSidebar}
                  subtitle={subtitle}
                  link={link ?? '/'}
                  key={idx}
                />
              </>
            )}
          </div>
        )
      })}
    </>
  )
}

export default Sidebar
