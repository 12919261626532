import { Icon as UIIcon, List as UIList } from '@faststore/ui'
import Icon from 'src/components/ui/Icon'
import Link from 'src/components/ui/Link'
import SROnly from 'src/components/ui/SROnly'
import { mark } from 'src/sdk/tests/mark'
import LogoMobile from 'src/components/ui/Logo/LogoMobile'
import { GooglePlay } from 'src/components/ui/Icons/GooglePlay'
import { Image } from 'src/components/ui/Image'
import { useMediaQuery } from 'react-responsive'
import { usePageFooter } from 'src/sdk/analytics/hooks/usePageFooter'

import { useEffect, useState } from 'react'
import { useCmsHomeQuery } from '../../hooks/useCmsHomeQuery'
import FooterLinks from './FooterLinks'


function Footer() {
  const [triggerEvent, setTriggerEvent] = useState(false)
  const { footerLinks } = useCmsHomeQuery()
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1280px)',
  })
  const leftLinks = footerLinks?.leftLinks
  const socialLinks = footerLinks?.socialLinks
  const paymentImage = footerLinks?.paymentImage
  const ecommerceUYImage = footerLinks?.ecommerceUYImage
  const imageTitle = footerLinks?.imageTitle
  const index = isDesktopOrLaptop ? 0 : 1
  const { sendItemPromotionEvent } = usePageFooter()

  useEffect(() => {
    if (!triggerEvent) {
      setTriggerEvent(true)
      sendItemPromotionEvent({
        id: 'footerLoaded',
        nameEvent: 'footerLoaded',
      })
    }
  }, [])

  const SelloItem = () => (
    <div className="footer__sello">
      <div className="footer__sello-icons">
        <Image
          src={ecommerceUYImage?.[index]?.image}
          alt={ecommerceUYImage?.[index]?.alt}
          width={95}
          height={90}
        />
      </div>
      <div className="footer__sello-title">
        <span>{ecommerceUYImage?.[index]?.titleAwards}</span>
      </div>
    </div>
  )

  return (
    <>
      <div className="footer__redbar" />
      <footer className="footer layout__content-full">
        <div className="footer__container">
          <div className="footer__logo-tata">
            <LogoMobile />
          </div>

          <div className="infos">
            <a href={`tel:+${leftLinks?.[0]?.url}`} className="infos__link">
              {leftLinks?.[0]?.title}
            </a>
            <a href={`mailto:${leftLinks?.[1]?.url}`} className="infos__link">
              {leftLinks?.[1]?.title}
            </a>
            <div className="infos__container">
              <h3 className="infos__title">Linea ética</h3>
              <a href={leftLinks?.[2]?.url} className="infos__link">
                {leftLinks?.[2]?.title}
              </a>
            </div>
            <div className="infos__container">
              <h3 className="infos__title">Seguinos en:</h3>
              <section className="footer__social">
                <UIList variant="unordered">
                  <li style={{ width: '24px', height: '24px' }}>
                    <Link
                      href={socialLinks?.[0]?.url ?? '/#'}
                      title="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UIIcon
                        style={{ width: '24px', height: '24px' }}
                        component={
                          <Icon width="24px" height="24px" name="Facebook" />
                        }
                      />
                    </Link>
                  </li>
                  <li style={{ width: '24px', height: '24px' }}>
                    <Link
                      href={socialLinks?.[1]?.url ?? '/#'}
                      title="Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UIIcon
                        component={
                          <Icon width="24px" height="24px" name="Instagram" />
                        }
                      />
                    </Link>
                  </li>
                  <li style={{ width: '24px', height: '24px' }}>
                    <Link
                      href={socialLinks?.[2]?.url ?? '/#'}
                      title="Pinterest"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UIIcon
                        component={
                          <Icon width="24px" height="24px" name="Youtube" />
                        }
                      />
                    </Link>
                  </li>
                  <li style={{ width: '24px', height: '24px' }}>
                    <Link
                      href={socialLinks?.[3]?.url ?? '/#'}
                      title="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UIIcon
                        component={
                          <Icon width="24px" height="24px" name="Linkedin" />
                        }
                      />
                    </Link>
                  </li>
                </UIList>
              </section>
            </div>
          </div>
        </div>

        <div className="footer__download">
          <h3 className="download__title">Descargá gratis ahora mismo</h3>
          <div className="download__icons">
            <div className="download__google-play">
              <Link href={footerLinks?.googlePlay ?? '/'}>
                <GooglePlay />
              </Link>
            </div>
            <div className="download__app-store">
              <Link href={footerLinks?.appStore ?? '/'}>
                <Icon width="136px" height="40px" name="AppStore" />
              </Link>
            </div>
          </div>
        </div>

        <div className="footer__container-redlinks">
          <div className="footer__section layout__content">
            <FooterLinks />
            {isDesktopOrLaptop && <SelloItem />}
          </div>
        </div>
        <div>
          <div className="footer__note layout__content">
            <p className="text__title-mini">{imageTitle ?? 'Medio de Pago'}</p>
            <Image
              className="footer__payment-icons"
              src={paymentImage?.[0]?.srcSet}
              alt={paymentImage?.[0]?.alt}
              width={332}
              height={110}
            />
          </div>
          <div>
            <div className="footer__apps hidden-mobile">
              <h3 className="footer__apps__title">
                Descargá gratis ahora mismo
              </h3>
              <div className="footer__apps__icons">
                <Link href={footerLinks?.googlePlay ?? '/'}>
                  <div className="footer__googleplay" />
                </Link>
                <Link href={footerLinks?.appStore ?? '/'}>
                  <div className="download__app-store">
                    <Icon width="136px" height="40px" name="AppStore" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {!isDesktopOrLaptop && (
          <>
            <hr className="footer__sello-divider" />
            <SelloItem />
          </>
        )}
      </footer>
      <div className="footer__copyright">
        <div className="footer__copyright__container">
          <div className="footer__copyright__firstline">
            <p>Desarrollado por</p>
            <Icon width="46px" height="16px" name="Acct" />
            <SROnly text="Diners Club" />
            <Icon width="44px" height="16px" name="Vtex" />
            <SROnly text="Diners Club" />
          </div>
          <div className="footer__copyright__secondline">
            <p>© Tata 2022. Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
    </>
  )
}

Footer.displayName = 'Footer'

export default mark(Footer)
