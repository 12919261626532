import Icon from 'src/components/ui/Icon'
import { ButtonLink } from 'src/components/ui/Button'
import { useSession } from 'src/sdk/session'
import styles from 'src/components/ui/Button/button.module.scss'

import storeConfig from '../../../../../../store.config'

const ButtonSignIn = () => {
  const { person } = useSession()
  const givenName = person?.givenName
  const userName = (givenName && `Hola, ${givenName}`) ?? 'Perfil'

  return (
    <>
      <div style={{ display: 'flex' }}>
        <ButtonLink
          data-fs-button-signin-link
          href={person?.id ? storeConfig.accountUrl : storeConfig.loginUrl}
          className={`${styles.fsButton} text__title-mini signin-link`}
          variant="tertiary"
        >
          <Icon name="User" width={18} height={18} weight="bold" />
        </ButtonLink>
        <h4 className="button__username">{userName}</h4>
      </div>
    </>
  )
}

export default ButtonSignIn
