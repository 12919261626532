import { graphql, useStaticQuery } from 'gatsby'

export const useCmsHomeQuery = () => {
  const data = useStaticQuery(
    graphql`
      query cmsHomeQuery {
        cmsHome {
          sections {
            data
          }
        }
      }
    `
  )

  const stickBarTexts: StickBarData[] =
    data?.cmsHome?.sections?.[0]?.data?.allItems

  const bannerImages: BannerImagesData[] =
    data?.cmsHome?.sections?.[1]?.data?.allItems

  const collectionsListData: CollectionsListData =
    data?.cmsHome?.sections?.[2]?.data

  const bannerText: BannerTextsData[] = data?.cmsHome?.sections?.[3]?.data

  const richText: RichTextData[] = data?.cmsHome?.sections?.[4]?.data

  const NavigationCategoryData: TNavigation = data?.cmsHome?.sections?.[5]?.data

  const promotionalBannerDataOne: TPromotional =
    data?.cmsHome?.sections?.[6]?.data

  const promotionalBannerDataTwo: TPromotional =
    data?.cmsHome?.sections?.[7]?.data

  const promotionalFixedBanner: TPromotional =
    data?.cmsHome?.sections?.[8]?.data

  const promotionalMosaicBanner: TPromotional =
    data?.cmsHome?.sections?.[9]?.data

  const promotionalBannerDataFour: TPromotional =
    data?.cmsHome?.sections?.[10]?.data

  const promotionalBannerDataFive: TPromotional =
    data?.cmsHome?.sections?.[11]?.data

  const promotionalLastBanner: TPromotional =
    data?.cmsHome?.sections?.[12]?.data

  const footerLinks: FooterLinks = data?.cmsHome?.sections?.[13]?.data

  const collectionShelfOne: TShelf = data?.cmsHome?.sections?.[14]?.data

  const collectionShelfTwo: TShelf = data?.cmsHome?.sections?.[15]?.data

  const collectionShelfThree: TShelf = data?.cmsHome?.sections?.[16]?.data

  const collectionShelfFour: TShelf = data?.cmsHome?.sections?.[17]?.data

  const collectionShelfFive: TShelf = data?.cmsHome?.sections?.[18]?.data

  const menuData: TCategory[] = data?.cmsHome?.sections?.[19]?.data?.categories

  const sidebarData: SidebarMenu[] =
    data?.cmsHome?.sections?.[19]?.data?.sidebar

  return {
    data,
    stickBarTexts,
    bannerImages,
    collectionsListData,
    bannerText,
    richText,
    NavigationCategoryData,
    promotionalBannerDataOne,
    promotionalBannerDataTwo,
    promotionalFixedBanner,
    promotionalMosaicBanner,
    promotionalBannerDataFour,
    promotionalBannerDataFive,
    promotionalLastBanner,
    footerLinks,
    collectionShelfOne,
    collectionShelfTwo,
    collectionShelfThree,
    collectionShelfFour,
    collectionShelfFive,
    menuData,
    sidebarData,
  }
}
