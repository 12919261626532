import type { Dispatch, FC, SetStateAction } from 'react'
import { useEffect, useState, lazy, Suspense } from 'react'
import { useCmsMenuQuery } from 'src/components/hooks/useCmsCategoryQuery'
import Section from 'src/components/sections/Section'

import { categoriesDataMock } from './data/categoriesDataMock'

const CategoriesItens = lazy(
  () => import('src/components/common/Categories/CategoriesItens')
)

interface Props {
  setHideSidebarMenu: Dispatch<SetStateAction<boolean>>
}

const Categories: FC<Props> = ({ setHideSidebarMenu }) => {
  const { data } = categoriesDataMock
  const { menuData } = useCmsMenuQuery()

  const renderLoader = () => null

  const [menuState, setMenuState] = useState<CategoriesStateProps>({
    menuIndex: 0,
    title: 'Categorías',
    titlePrev: '',
    allCategories: null,
    currentCategory: null,
  })

  useEffect(() => {
    if (data) {
      setMenuState({
        ...menuState,
        allCategories: menuData ?? data.categories,
        currentCategory: menuData ?? data.categories,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    setHideSidebarMenu(menuState.menuIndex !== 0)
  }, [menuState, setHideSidebarMenu])

  return (
    <Section>
      <div className="menu__categories" data-menu-idx={menuState.menuIndex}>
        <Suspense fallback={renderLoader()}>
          <CategoriesItens menuState={menuState} setMenuState={setMenuState} />
        </Suspense>
      </div>
    </Section>
  )
}

export default Categories
