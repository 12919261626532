export default function GeoInnerIcon() {
  return (
    <svg
      width="32.5"
      height="30.88"
      viewBox="0 0 32.5 30.88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.75 5.125C32.75 2.43725 30.5627 0.25 27.875 0.25H5.125C2.43725 0.25 0.25 2.43725 0.25 5.125V9.72538C0.25 11.4365 0.87075 12.9916 1.875 14.1876V27.875C1.875 29.6674 3.33262 31.125 5.125 31.125H27.875C29.6674 31.125 31.125 29.6674 31.125 27.875V14.186C32.1292 12.9916 32.75 11.4365 32.75 9.72375V5.125ZM29.5 5.125V9.72538C29.5 11.5779 28.1204 13.1574 26.4271 13.2467L26.25 13.25C24.4576 13.25 23 11.7924 23 10V3.5H27.875C28.772 3.5 29.5 4.22962 29.5 5.125ZM13.25 3.5H19.75V10C19.75 11.7924 18.2924 13.25 16.5 13.25C14.7076 13.25 13.25 11.7924 13.25 10V3.5ZM3.5 5.125C3.5 4.22962 4.228 3.5 5.125 3.5H10V10C10 11.7924 8.54237 13.25 6.75 13.25L6.57287 13.2451C4.87962 13.1574 3.5 11.5779 3.5 9.72538V5.125ZM13.25 27.875V23H19.75V27.875H13.25ZM23 27.875V23C23 21.2076 21.5424 19.75 19.75 19.75H13.25C11.4576 19.75 10 21.2076 10 23V27.875H5.125V16.2871C5.53775 16.396 5.96513 16.4675 6.40388 16.4903C7.38357 16.5434 8.36254 16.3735 9.26697 15.9932C10.1714 15.6129 10.9777 15.0322 11.625 14.2949C12.8161 15.6469 14.5614 16.5 16.5 16.5C18.4386 16.5 20.1839 15.6469 21.375 14.2949C22.0223 15.0322 22.8286 15.6129 23.733 15.9932C24.6375 16.3735 25.6164 16.5434 26.5961 16.4903C27.0365 16.4675 27.4622 16.396 27.875 16.2871V27.875H23Z"
        fill="#E5002B"
      />
    </svg>
  )
}
