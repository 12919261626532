import { Button, List as UIList } from '@faststore/ui'
import { useCmsHomeQuery } from 'src/components/hooks/useCmsHomeQuery'
import { ButtonIcon } from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import Link from 'src/components/ui/Link'
import { useUI } from 'src/sdk/ui/Provider'

import { collectionsDataMock } from './NavBarCollectionsDataMock'

function NavLinks() {
  const { collectionsListData } = useCmsHomeQuery()
  const { openNavbar } = useUI()

  const collections: CollectionsList[] =
    collectionsListData?.allItems ?? collectionsDataMock.allItems

  return (
    <nav className="navlinks__list">
      <div className="navlinks__list-mobile display-mobile">
        {collections?.map(({ link, text }, idx) => (
          <li className="navlinks__collection" key={idx}>
            <Link
              className="navlinks__collection--text"
              variant="display"
              href={link}
            >
              {text}
            </Link>
          </li>
        ))}
        <div className="navlinks__contact" style={{ display: 'flex' }}>
          <img
            src="https://tatauyqa.vtexassets.com/arquivos/tel.gif"
            alt="tel gif"
            width={15}
            height={15}
          />
          <div
            dangerouslySetInnerHTML={{
              __html:
                collectionsListData?.contactText ??
                '<p className="navlinks__contact-text"> Televentas <b>08008282</b></p>',
            }}
          />
        </div>
        <div className="navlinks__container__redlink">
          <Link
            className="navlinks__redlink__box"
            href={collectionsListData?.redButton.redButtonLink ?? '/'}
          >
            {collectionsListData?.redButton.redButtonText ?? 'Bombazos'}
          </Link>
        </div>
        <div className="navlinks__whats">
          <Link
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=%2B5491126984796&text=Hola%2C+necesito+asistencia&type=phone_number&app_absent=0"
          >
            <img
              src="/WhatsAppLogo.svg"
              alt="bagIcon"
              width="60px"
              height="60px"
            />
          </Link>
        </div>
      </div>

      <div className="navlinks__category-box hidden-mobile">
        <div className="navlinks__categories" style={{ display: 'flex' }}>
          <ButtonIcon
            data-fs-button-menu
            testId="menu--desktop"
            aria-label="Open Menu"
            icon={<Icon name="ListMenu" width={19} height={18} />}
            onClick={openNavbar}
          />
          <Button className="navlinks__button--open" onClick={openNavbar}>
            <h2 className="navlinks__category-text">CATEGORÍAS</h2>
          </Button>
        </div>
        <UIList className="navlinks__collections">
          {collections?.map(({ link, text }, idx) => (
            <li className="navlinks__collection" key={idx}>
              <Link
                className="navlinks__collection--text"
                variant="display"
                href={link}
              >
                {text}
              </Link>
            </li>
          ))}
          <div className="navlinks__contact" style={{ display: 'flex' }}>
            <img
              src="https://tatauyqa.vtexassets.com/arquivos/tel.gif"
              alt="tel gif"
              width={15}
              height={15}
            />
            <div
              dangerouslySetInnerHTML={{
                __html:
                  collectionsListData?.contactText ??
                  '<p className="navlinks__contact-text"> Televentas <b>08008282</b></p>',
              }}
            />
          </div>
          <div className="navlinks__container__redlink">
            <Link
              className="navlinks__redlink__box"
              href={collectionsListData?.redButton.redButtonLink ?? '/'}
            >
              {collectionsListData?.redButton.redButtonText ?? 'Bombazos'}
            </Link>
          </div>
          <div className="navlinks__whats">
            <Link
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=%2B5491126984796&text=Hola%2C+necesito+asistencia&type=phone_number&app_absent=0"
            >
              <img
                src="/WhatsAppLogo.svg"
                alt="bagIcon"
                width="60px"
                height="60px"
              />
            </Link>
          </div>
        </UIList>
      </div>
    </nav>
  )
}

export default NavLinks
