import { useCallback } from 'react'
import { sendAnalyticsEvent } from '@faststore/sdk'

export type Props = {
  href?: string
  title?: string
  nameEvent: string
  id: string | number
  link?: string
}

export interface SelectPromotionEvent {
  name: string
  params: ViewItemListParams
}

export interface ViewItemListParams {
  item_list_id?: number | string
}

export const usePageFooter = () => {
  const sendItemPromotionEvent = useCallback(
    
    ({ nameEvent, id }: Props) => {
      sendAnalyticsEvent<SelectPromotionEvent>({
        name: nameEvent,
        params: {
          item_list_id: id,
        },
      })
    },
    []
  )

  return { sendItemPromotionEvent }
}
