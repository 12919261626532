import type { FC } from 'react'

export const Locate: FC<IconProps> = ({ className }) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 8H0.25H1ZM8 19L8.42131 19.6205C8.16698 19.7932 7.83302 19.7932 7.57869 19.6205L8 19ZM15 8H14.25H15ZM1.75 8C1.75 11.1016 4.14495 15.4759 8.42131 18.3795L7.57869 19.6205C3.0185 16.5241 0.25 11.735 0.25 8H1.75ZM7.57869 18.3795C11.8551 15.4759 14.25 11.1016 14.25 8H15.75C15.75 11.735 12.9815 16.5241 8.42131 19.6205L7.57869 18.3795ZM14.25 8C14.25 4.06425 11.0759 1.75 8 1.75V0.25C11.7607 0.25 15.75 3.09919 15.75 8H14.25ZM8 1.75C4.92415 1.75 1.75 4.06425 1.75 8H0.25C0.25 3.09919 4.2393 0.25 8 0.25V1.75ZM8 5.75C7.11561 5.75 6.59007 5.97059 6.28033 6.28033C5.97059 6.59007 5.75 7.11561 5.75 8H4.25C4.25 6.88439 4.52941 5.90993 5.21967 5.21967C5.90993 4.52941 6.88439 4.25 8 4.25V5.75ZM8 4.25C9.11561 4.25 10.0901 4.52941 10.7803 5.21967C11.4706 5.90993 11.75 6.88439 11.75 8H10.25C10.25 7.11561 10.0294 6.59007 9.71967 6.28033C9.40993 5.97059 8.88439 5.75 8 5.75V4.25ZM8 11.75C6.88439 11.75 5.90993 11.4706 5.21967 10.7803C4.52941 10.0901 4.25 9.11561 4.25 8H5.75C5.75 8.88439 5.97059 9.40993 6.28033 9.71967C6.59007 10.0294 7.11561 10.25 8 10.25V11.75ZM8 10.25C8.88439 10.25 9.40993 10.0294 9.71967 9.71967C10.0294 9.40993 10.25 8.88439 10.25 8H11.75C11.75 9.11561 11.4706 10.0901 10.7803 10.7803C10.0901 11.4706 9.11561 11.75 8 11.75V10.25Z"
      fill="white"
    />
  </svg>
)
