export default function GeoPromoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        opacity="0.15"
        d="M20.5 10.75C20.5 16.1348 16.1348 20.5 10.75 20.5C5.36522 20.5 1 16.1348 1 10.75C1 5.36522 5.36522 1 10.75 1C16.1348 1 20.5 5.36522 20.5 10.75Z"
        fill="#005EE2"
      />
      <path
        d="M10 16.1667C10 16.5809 10.3358 16.9167 10.75 16.9167C11.1642 16.9167 11.5 16.5809 11.5 16.1667H10ZM11.5 8.58333C11.5 8.16912 11.1642 7.83333 10.75 7.83333C10.3358 7.83333 10 8.16912 10 8.58333H11.5ZM10 5.34413C10 5.75834 10.3358 6.09413 10.75 6.09413C11.1642 6.09413 11.5 5.75834 11.5 5.34413H10ZM11.5 5.3333C11.5 4.91909 11.1642 4.5833 10.75 4.5833C10.3358 4.5833 10 4.91909 10 5.3333H11.5ZM11.5 16.1667L11.5 8.58333H10L10 16.1667H11.5ZM11.5 5.34413V5.3333H10V5.34413H11.5ZM19.75 10.75C19.75 15.7206 15.7206 19.75 10.75 19.75V21.25C16.549 21.25 21.25 16.549 21.25 10.75H19.75ZM10.75 19.75C5.77944 19.75 1.75 15.7206 1.75 10.75H0.25C0.25 16.549 4.95101 21.25 10.75 21.25V19.75ZM1.75 10.75C1.75 5.77944 5.77944 1.75 10.75 1.75V0.25C4.95101 0.25 0.25 4.95101 0.25 10.75H1.75ZM10.75 1.75C15.7206 1.75 19.75 5.77944 19.75 10.75H21.25C21.25 4.95101 16.549 0.25 10.75 0.25V1.75Z"
        fill="#005EE2"
      />
    </svg>
  )
}
