const ACCOUNT = 'jamstacktata'

module.exports = {
  // Theming
  theme: 'tatauyqa',

  // Ecommerce Platform
  platform: 'vtex',

  // Platform specific configs for API
  api: {
    storeId: ACCOUNT,
    workspace: 'master',
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  // Default channel
  session: {
    currency: {
      code: 'UYU',
      symbol: '$',
    },
    locale: 'es-uy',
    channel: '{"salesChannel":"4","regionId":""}',
    country: 'URY',
    postalCode: '11800',
    person: null,
  },

  // Production URLs
  storeUrl: 'https://jamstack.tata.com.uy',
  secureSubdomain: 'https://secure.jamstack.tata.com.uy/',
  checkoutUrl: 'https://secure.jamstack.tata.com.uy/checkout',
  loginUrl: 'https://secure.jamstack.tata.com.uy/api/io/login',
  accountUrl: 'https://secure.jamstack.tata.com.uy/api/io/account',

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:9000',
    pages: {
      home: '/',
      pdp: '/cerveza-heineken-1-litro-no-retornable-162/p',
      collection: '/cervezas',
    },
  },

  // E2E CI
  cypress: {
    pages: {
      home: '/',
      pdp: '/cerveza-heineken-1-litro-no-retornable-162/p',
      collection: '/cervezas',
      collection_filtered:
        '/cervezas/?category-1=bebidas&category-2=cervezas&brand=heineken&facets=category-1%2Ccategory-2%2Cbrand&sort=score_desc&page=0',
      search: '/s?q=heineken',
    },
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId: 'GTM-PHQC9D6',
  },
}
