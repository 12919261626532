function MiniCartIcon() {
  return (
    <img
      src="/minicartIcon.svg"
      alt="minicartIcon"
      width="24px"
      height="24px"
    />
  )
}

export default MiniCartIcon
