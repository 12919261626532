import { useState, useEffect, useCallback } from 'react'

/**
 *
 * @description This is a non compliant React component and may lead
 * to undesired behavior if used on SSR. DO NOT USE IT unless you know
 * this component will not be used during SSR. If you need SSR, render
 * both versions of your page and hide it using CSS + media query
 */

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined'
  // See breakpoints on styles/global.scss
  const notebookBreakpoint = '1280'
  const tabletBreakpoint = '1025'
  const phoneCategoryBreakpoint = '450'

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window.innerWidth : 1023
    const height = hasWindow ? window.innerHeight : null
    const isMobile = width ? width < parseInt(notebookBreakpoint, 10) : null

    const isTablet = width
      ? width < parseInt(tabletBreakpoint, 10) &&
        width > parseInt(phoneCategoryBreakpoint)
      : null

    const isPhoneCategory = width
      ? width < parseInt(phoneCategoryBreakpoint, 10)
      : null
    return {
      width,
      height,
      isMobile,
      isTablet,
      isPhoneCategory,
    }
  }, [hasWindow, notebookBreakpoint])

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    if (!hasWindow) {
      return undefined
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [hasWindow, getWindowDimensions])

  return windowDimensions
}
