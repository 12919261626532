import type { Dispatch, FC, SetStateAction } from 'react'
import { useCmsMenuQuery } from 'src/components/hooks/useCmsCategoryQuery'

import Categories from '../../Categories'
import Sidebar from './Sidebar'

interface Props {
  hideSidebarMenu: boolean
  setHideSidebarMenu: Dispatch<SetStateAction<boolean>>
}

const SidebarMenu: FC<Props> = ({ hideSidebarMenu, setHideSidebarMenu }) => {
  const { sidebarData } = useCmsMenuQuery()

  return (
    <div className="menu__container" data-hide-sidebar-menu={hideSidebarMenu}>
      <Sidebar sidebarData={sidebarData}>
        <Categories setHideSidebarMenu={setHideSidebarMenu} />
      </Sidebar>
    </div>
  )
}

export default SidebarMenu
