/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-destructuring */
import type { PropsWithChildren } from 'react'
import { createContext, lazy, Suspense, useState, useEffect } from 'react'
import Footer from 'src/components/common/Footer'
import Navbar from 'src/components/common/Navbar'
import Toast from 'src/components/common/Toast'
import RegionalizationBar from 'src/components/regionalization/RegionalizationBar'
import { useUI } from 'src/sdk/ui/Provider'
// import { setAddressType } from 'src/utils/setAddressType'
import { useCart } from 'src/sdk/cart'

import StickBarCustom from './components/common/CustomStickBar/CustomStickBar'
import NavLinks from './components/common/Navbar/Navlinks'
import { useCmsHomeQuery } from './components/hooks/useCmsHomeQuery'
import { mockTexts } from './mocks/StickBarMocks'

import 'src/styles/pages/layout.scss'

const CartSidebar = lazy(() => import('src/components/cart/CartSidebar'))
const RegionModal = lazy(
  () => import('src/components/regionalization/RegionalizationModal')
)

type HeaderSizeContextProps = {
  headerSize: number | null
  setHeaderSize: (number: number | null) => void
  isTelemarketingBarVisible: boolean
  setIsTelemarketingBarVisible: (isTelemarketing: boolean) => void
}

export const HeaderSizeContext = createContext<HeaderSizeContextProps | null>(
  null
)

function Layout({ children }: PropsWithChildren) {
  const { stickBarTexts } = useCmsHomeQuery()
  const { cart: displayCart, modal: displayModal } = useUI()
  const topBarTexts = stickBarTexts?.map((stickBarText) => stickBarText?.text)
  const texts = topBarTexts ?? mockTexts

  const [isTelemarketingBarVisible, setIsTelemarketingBarVisible] =
    useState(false)

  const [headerSize, setHeaderSize] = useState<number | null>(null)

  // const { id } = useCart()

  // useEffect(() => {
  //   setAddressType(id)
  // }, [id])

  return (
    <>
      <HeaderSizeContext.Provider
        value={{
          headerSize,
          setHeaderSize,
          isTelemarketingBarVisible,
          setIsTelemarketingBarVisible,
        }}
      >
        <div className="navBar__container-sticky-desktop hidden-mobile">
          <StickBarCustom texts={texts} />

          <div className="navbar__sticky__header-mobile">
            <Navbar isVisible />
          </div>
          <NavLinks />
        </div>

        <div className="navBar__StickBar-mobile display-mobile">
          <StickBarCustom texts={texts} />
        </div>

        <div className="navbar__sticky__header-mobile display-mobile">
          <Navbar isVisible={false} />
        </div>

        <div className="navBar__NavLinks-mobile display-mobile">
          <NavLinks />
        </div>

        <Toast />

        <main>
          <RegionalizationBar classes="display-mobile" />
          {children}
        </main>

        <Footer />
      </HeaderSizeContext.Provider>

      {displayCart && (
        <Suspense fallback={null}>
          <CartSidebar />
        </Suspense>
      )}

      {displayModal && (
        <Suspense fallback={null}>
          <RegionModal />
        </Suspense>
      )}
    </>
  )
}

export default Layout
