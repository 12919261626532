import { graphql, useStaticQuery } from 'gatsby'
export const useGeolocations = () => {
  return useStaticQuery(
    graphql`
      query Geolocations {
        geolocations {
          _0 {
            Name
          }
          _9 {
            Name
          }
          _10 {
            Name
          }
          _5 {
            Name
          }
          _11 {
            Name
          }
          _13 {
            Name
          }
          _15 {
            Name
          }
          _2 {
            Name
          }
          _7 {
            Name
          }
          _6 {
            Name
          }
          _8 {
            Name
          }
          _3 {
            Name
          }
          _4 {
            Name
          }
          _12 {
            Name
          }
          _1 {
            Name
          }
        }
      }
    `
  )
}
